/* global footerSectionComponent */
class FooterComponent extends footerSectionComponent {

    getComputed() {
        let superComputed = super.getComputed();
        superComputed.linkWP = function() {
            return `https://wa.me/${this.$store.getters.getSetting.WhatsAppNumber}`
        }
        return superComputed;
    }

    getTemplate(){
        return `<footer class="container-fluid" v-if="mustRender">
                    <div class="row py-3 social">
                        <div class="col">
                            <strong>Seguinos en redes</strong>
                            <a v-if="enableFB" :href="linkFB" target="_blank"><span class="social-icon"><i class="icon fab fa-facebook-f"></i></span></a>
                            <a v-if="enableIG" :href="linkIG" target="_blank"><span class="social-icon"><i class="icon fab fa-instagram"></i></span></a>
                            <a v-if="enableLK" :href="linkLK" target="_blank"><span class="social-icon"><i class="icon fa-brands fa-linkedin-in"></i></span></a>
                            <a v-if="enableTW" :href="linkTW" target="_blank"><span class="social-icon"><i class="icon fa-brands fa-twitter"></i></span></a>
                            <a :href="linkWP" target="_blank"><span class="social-icon"><i class="icon fab fa-whatsapp"></i></span></a>
                            <!-- a href="https://www.youtube.com/channel/UCAVvTUuQ045fUgpSldCaY3A" target="_blank"><span class="social-icon"><i class="icon fab fa-youtube"></i></span></a -->
                        </div>
                    </div>
                    <div class="container footer-content">
                        <div class="row py-3 justify-content-center">
                            <!-- div class="col-12 col-sm-6 col-lg-3 mb-3 mb-lg-0 text-left">
                                <span class="footer-title"><strong>Rodamientos y Accesorios</strong></span>
                                <router-link to="/category/RodaCombi__Rodamientos%20Combinados?category=RodaCombi__Rodamientos%20Combinados"><p>Rodamientos Combinados</p></router-link>
                            </div -->
                            <!-- div class="col-12 col-sm-6 col-lg-3 mb-3 mb-lg-0 text-left">
                                <span class="footer-title"><strong>Equipos y Herramientas</strong></span>
                                <router-link to="/category/AccEquipHerr__Accesorios%20de%20Equipos%20y%20Herramientas?category=AccEquipHerr__Accesorios%20de%20Equipos%20y%20Herramientas"><p>Accesorios de Equipos y Herramientas</p></router-link>
                            </div -->
                            <div class="col-12 col-sm-6 col-lg-3 mb-3 mb-lg-0 text-left">
                                <span class="footer-title"><strong>Institucional</strong></span>
                                <router-link to="/aboutus"><p>Sobre Nosotros</p></router-link>
                                <!-- router-link to="/contact"><p>{{tr('Contacto')}}</p></router-link -->
                            </div>
                            <div class="col-12 col-sm-6 col-lg-3 mb-3 mb-lg-0 text-left contact-us">
                                <p><a href="https://goo.gl/maps/" target="_blank"><i class="icon fas fa-map-marker-alt"></i> Av Provincias Unidas 136 - Rosario (2000) - Santa Fe - Argentina</a></p>
                                <p><a href="tel:+541143812239"><i class="icon fas fa-phone-alt"></i> Tel +54 9 341-7444787</a></p>
                                <p><a href="mailto:consultas@storeinhouse.com" class="mail-link"><i class="icon fas fa-envelope"></i> consultas@storeinhouse.com.ar</a></p>
                                <!-- p class="text-right"><a href="./../assets/DATA_FISCAL_LINK" download=""><img class="img-fluid" src="./../assets/DATA_FISCAL"></a></p -->
                            </div>
                        </div>
                        <div class="row">
                            <div class="col bottom-links">
                                <router-link to="/privacidad">{{tr('Políticas de privacidad')}}</router-link>
                                <router-link to="/terminos">{{tr('Términos y condiciones')}}</router-link>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center py-2 copyright-footer">
                        <div class="col-12 col-md-6 text-center text-md-left">
                            Copyright © {{year}} <a href="#" class="brand">{{menuTitle}}</a> All rights reserved.
                        </div>
                        <div class="col-12 col-md-6 text-center text-md-right mt-2 mt-md-0">
                            <span class="right">with <i class="icon fas fa-heart"></i> by <a href="https://oppen.io/" target="_blank" class="brand">öppen</a></span>
                        </div>
                    </div>
                </footer>`;
    }
}

FooterComponent.registerComponent();
